var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
      [
        _c("c-table", {
          ref: "grid1",
          attrs: {
            title: "평가항목",
            tableId: "grid1",
            columnSetting: false,
            isFullScreen: false,
            usePaging: false,
            filtering: false,
            hideBottom: true,
            columns: _vm.grid1.columns,
            data: _vm.grid1.data,
          },
          on: { rowClick: _vm.rowClick },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9" },
      [
        _c("c-table", {
          ref: "grid2",
          attrs: {
            title: "평가항목별 세부항목",
            tableId: "grid2",
            columnSetting: false,
            isFullScreen: false,
            usePaging: false,
            filtering: false,
            hideBottom: true,
            merge: _vm.grid2.merge,
            columns: _vm.grid2.columns,
            data: _vm.grid2.data,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }